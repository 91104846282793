import supabase from "@/libs/supabase";
import store from "@/store";
import { v4 as uuidv4 } from 'uuid';

export async function saveUserProfile(form) {

    try {
        const data = {
            user_firstname: form.user_firstname,
            user_lastname: form.user_lastname,
        };

        const user_uid = store.getters["user/getUserSession"].user.id;

        if (form.user_image_file) {
            const { data: upload_data, error: upload_error } = await supabase
                .storage
                .from("bucket0/profile-images")
                .upload(`${user_uid}-profile-${uuidv4()}`, form.user_image_file, {
                    upsert: true
                });
            if (upload_error) throw new Error(JSON.stringify(upload_error));
            console.log({ upload_data });
            data.user_profile_image = supabase.storage.from("").getPublicUrl(upload_data.Key).data.publicURL;
        }

        const { data: update_data, error: update_error } = await supabase
            .from("user_details")
            .update(data)
            .match({ user_uid });
        if (update_error && typeof update_error !== "object") throw new Error(JSON.stringify(update_error));

        console.log({ update_data, update_error });

        if (!update_data && update_error.length === 0) {
            const { data: insert_data, error: insert_error } = await supabase
                .from("user_details")
                .insert({
                    user_uid,
                    ...data
                });
            console.log({ insert_data, insert_error });
            if (insert_error) throw new Error(JSON.stringify(insert_error));
        }

        return true;
    } catch (error) {
        console.log('Error in saveUserProfile ', typeof error === "string" ? JSON.parse(error) : error);
        return null;
    }
}