<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img rounded :src="form.user_profile_image" height="80" />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refImageInput.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refImageInput"
          accept=".jpg, .png"
          :hidden="true"
          plain
          v-model="form.user_image_file"
          @input="uploadImage"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="resetImage"
        >
          Reset
        </b-button>
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="First Name" label-for="user_firstname">
            <b-form-input
              v-model="form.user_firstname"
              placeholder="First Name"
              name="user_firstname"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Last Name" label-for="user_lastname">
            <b-form-input
              v-model="form.user_lastname"
              placeholder="Last Name"
              name="user_lastname"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="saveUserProfile"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@/@core/comp-functions/forms/form-utils";
import { saveUserProfile } from "./useSettings";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        user_profile_image: "",
        user_image_file: null,
        user_firstname: "",
        user_lastname: "",
      },
    };
  },
  watch: {
    "$store.state.user.user_details": function (val) {
      this.getUserDetails();
    },
  },
  methods: {
    resetImage() {
      this.form = {
        ...this.form,
        user_profile_image: null,
        user_image_file: null,
      };
    },
    uploadImage() {
      const { inputImageRenderer } = useInputImageRenderer(
        this.$refs["refImageInput"].$el,
        (data_url) => {
          this.form.user_profile_image = data_url;
        }
      );
      inputImageRenderer();
    },
    async saveUserProfile() {
      const saved = await saveUserProfile(this.form);
      if (saved) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Saved",
            icon: "EditIcon",
            variant: "success",
          },
        });
        this.$store.dispatch("user/setUserDetails");
      } else {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Not Saved",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },
    async getUserDetails() {
      const userDetails = this.$store.getters["user/getUserDetails"];
      this.form.user_firstname = userDetails.user_firstname;
      this.form.user_lastname = userDetails.user_lastname;
      this.form.user_profile_image = userDetails.user_profile_image;
    },
  },
  beforeMount() {
    this.getUserDetails();
  },
};
</script>
